$(document).ready(function(){

  $('.m-menu').click(function(){
    if ($('body').hasClass('m-menu-open')){
      $('body').removeClass('m-menu-open');
    }else{
      $('body').addClass('m-menu-open');
    }
  });

  $('#gallery').slick();
  $("#trailer").fitVids();

});
